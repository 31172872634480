/* Dropdown Button */
.dropbtn {
   background: none;
   color: white;
    font-size: 16px;
    border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: fixed;
    background-color: #f1f1f1;
    min-width: 220px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 8!important;
    border-radius: 15px;
  }

  @media only screen and (max-width: 768px) {
    .dropdown-content {
      left: 36%;
    }
  }
  

  @media only screen and (max-width: 425px) {
    .dropdown-content {
      left: 25%;
    }
  }


  @media only screen and (max-width: 375px) {
    .dropdown-content {
      left: 21%;
    }
  }

  @media only screen and (max-width: 320px) {
    .dropdown-content {
      left: 15%;
    }
  }
  
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black!important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
