.header {
    position: fixed;
    padding: 20px 0px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 6;
    transition: .3s ease-in;
    overflow: hidden;
}


.header-bg{
    background-color: rgba(0,0,0,.9);
}

.header .navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: #ffffff;
    
}

.header .navbar img {
    width: 160px;
    height: auto;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
    list-style-type: none;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
}


@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: rgba(0,0,0,.9);
    }

    .header .navbar {
        max-width: 90%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    
    }

    .nav-item {
        padding:8px 0px!important;
        margin: 3px 0;
        border-bottom: solid 1px gray;

    }

    .header .navbar img {
        width: 150px;
    }
}
















