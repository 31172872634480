.image-wrap {
    width: 500px;
    height: 450px;
    overflow: hidden;
    margin-top: 20px;
    border-radius: 20px;
    box-shadow: 0px 8px 20px 0px black;
}
.image-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    transition: all 7s ease-in-out;
}
.image-wrap:hover img {
    object-position: bottom center;
}


@media only screen and (max-width: 900px) {
    .image-wrap {
        width: 100%;
      
    }
  }