	/*
  	Flaticon icon font: Flaticon
  	Creation date: 01/08/2019 09:08
  	*/

	@font-face {
	  font-family: "Flaticon";
	  src: url("../fonts/Flaticon.eot");
	  src: url("../fonts/Flaticond41dd41d.html?#iefix") format("embedded-opentype"),
	    url("../fonts/Flaticon.woff2") format("woff2"),
	    url("../fonts/Flaticon.woff") format("woff"),
	    url("../fonts/Flaticon.ttf") format("truetype"),
	    url("../fonts/Flaticon.svg#Flaticon") format("svg");
	  font-weight: normal;
	  font-style: normal;
	}

	@media screen and (-webkit-min-device-pixel-ratio:0) {
	  @font-face {
	    font-family: "Flaticon";
	    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
	  }
	}

	[class^="flaticon-"]:before,
	[class*=" flaticon-"]:before,
	[class^="flaticon-"]:after,
	[class*=" flaticon-"]:after {
	  font-family: Flaticon;
	  font-size: inherit;
	  font-style: normal;
	}

	.flaticon-report:before {
	  content: "\f100";
	}

	.flaticon-clock:before {
	  content: "\f101";
	}

	.flaticon-call:before {
	  content: "\f102";
	}

	.flaticon-tourism:before {
	  content: "\f103";
	}

	.flaticon-process:before {
	  content: "\f104";
	}

	.flaticon-data:before {
	  content: "\f105";
	}

	.flaticon-global:before {
	  content: "\f106";
	}

	.flaticon-system:before {
	  content: "\f107";
	}

	.flaticon-padlock:before {
	  content: "\f108";
	}

	.flaticon-process-1:before {
	  content: "\f109";
	}

	.flaticon-data-1:before {
	  content: "\f10a";
	}

	.flaticon-work:before {
	  content: "\f10b";
	}

	.flaticon-data-analytics:before {
	  content: "\f10c";
	}

	.flaticon-chart:before {
	  content: "\f10d";
	}

	.flaticon-algorithm:before {
	  content: "\f10e";
	}

	.flaticon-data-2:before {
	  content: "\f10f";
	}

	.flaticon-clock-1:before {
	  content: "\f110";
	}

	.flaticon-microchip:before {
	  content: "\f111";
	}

	.flaticon-server:before {
	  content: "\f112";
	}

	.flaticon-24h:before {
	  content: "\f113";
	}

	.flaticon-global-1:before {
	  content: "\f114";
	}

	.flaticon-business-and-finance:before {
	  content: "\f115";
	}

	.flaticon-space:before {
	  content: "\f116";
	}

	.flaticon-information:before {
	  content: "\f117";
	}

	.flaticon-privacy:before {
	  content: "\f118";
	}

	.flaticon-alert:before {
	  content: "\f119";
	}

	.flaticon-online-library:before {
	  content: "\f11a";
	}

	.flaticon-info:before {
	  content: "\f11b";
	}

	.flaticon-interaction:before {
	  content: "\f11c";
	}

	.flaticon-mobile-app:before {
	  content: "\f11d";
	}

	.flaticon-code:before {
	  content: "\f11e";
	}

	.flaticon-developer:before {
	  content: "\f11f";
	}

	.flaticon-intelligent:before {
	  content: "\f120";
	}

	.flaticon-content:before {
	  content: "\f121";
	}

	.flaticon-laptop:before {
	  content: "\f122";
	}

	.flaticon-web:before {
	  content: "\f123";
	}

	.flaticon-content-writing:before {
	  content: "\f124";
	}

	.flaticon-computer:before {
	  content: "\f125";
	}

	.flaticon-computer-1:before {
	  content: "\f126";
	}

	.flaticon-graphic-design:before {
	  content: "\f127";
	}

	.flaticon-analysis:before {
	  content: "\f128";
	}

	.flaticon-bar-chart:before {
	  content: "\f129";
	}

	.flaticon-analytics:before {
	  content: "\f12a";
	}

	.flaticon-ui:before {
	  content: "\f12b";
	}

	.flaticon-resume:before {
	  content: "\f12c";
	}

	.flaticon-taxi:before {
	  content: "\f12d";
	}

	.flaticon-online-shop:before {
	  content: "\f12e";
	}

	.flaticon-smartphone:before {
	  content: "\f12f";
	}

	.flaticon-next-1:before {
	  content: "\f100";
	}

	.flaticon-back:before {
	  content: "\f101";
	}

	.flaticon-next:before {
	  content: "\f102";
	}

	.flaticon-back-1:before {
	  content: "\f103";
	}

	.flaticon-respect:before {
	  content: "\f104";
	}

	.flaticon-briefcase:before {
	  content: "\f105";
	}

	.flaticon-graduation-hat:before {
	  content: "\f106";
	}

	.flaticon-left-quote:before {
	  content: "\f107";
	}

	.flaticon-aeroplane:before {
	  content: "\f108";
	}

	.flaticon-jet:before {
	  content: "\f109";
	}

	.flaticon-phone-call-1:before {
	  content: "\f10a";
	}

	.flaticon-phone-call:before {
	  content: "\f10b";
	}

	.flaticon-customer-service:before {
	  content: "\f10c";
	}

	.flaticon-speech-bubble:before {
	  content: "\f10d";
	}

	.flaticon-shopping-basket:before {
	  content: "\f10e";
	}

	.flaticon-mail:before {
	  content: "\f10f";
	}

	.flaticon-graph:before {
	  content: "\f110";
	}

	.flaticon-compass:before {
	  content: "\f111";
	}

	.flaticon-home:before {
	  content: "\f112";
	}

	.flaticon-lifebuoy:before {
	  content: "\f113";
	}

	.flaticon-time:before {
	  content: "\f114";
	}


	.flaticon-artificial-intelligence:before {
	  content: "\f100";
	}

	.flaticon-robotic-arm:before {
	  content: "\f101";
	}

	.flaticon-ai:before {
	  content: "\f102";
	}

	.flaticon-play-button:before {
	  content: "\f103";
	}

	.flaticon-artificial-intelligence-1:before {
	  content: "\f104";
	}

	.flaticon-automaton:before {
	  content: "\f105";
	}

	.flaticon-brain:before {
	  content: "\f106";
	}

	.flaticon-ai-1:before {
	  content: "\f107";
	}

	.flaticon-artificial-intelligence-2:before {
	  content: "\f108";
	}

	.flaticon-artificial-intelligence-3:before {
	  content: "\f109";
	}

	.flaticon-mail:before {
	  content: "\f10a";
	}

	.flaticon-machine-learning:before {
	  content: "\f10b";
	}

	.flaticon-machine-learning-1:before {
	  content: "\f10c";
	}

	.flaticon-code:before {
	  content: "\f10d";
	}

	.flaticon-gps:before {
	  content: "\f10e";
	}

	.flaticon-place:before {
	  content: "\f10f";
	}

	.flaticon-phone:before {
	  content: "\f110";
	}

	.flaticon-mail-1:before {
	  content: "\f111";
	}

	.flaticon-artificial-intelligence-4:before {
	  content: "\f112";
	}

	.flaticon-abc:before {
	  content: "\f113";
	}

	.flaticon-contract:before {
	  content: "\f114";
	}

	.flaticon-quote:before {
	  content: "\f115";
	}

	.flaticon-business-and-finance:before {
	  content: "\f116";
	}

	.flaticon-binary-code:before {
	  content: "\f117";
	}

	.flaticon-database:before {
	  content: "\f118";
	}

	.flaticon-font:before {
	  content: "\f119";
	}

	.flaticon-speech-bubble:before {
	  content: "\f11a";
	}

	.flaticon-face:before {
	  content: "\f11b";
	}

	.flaticon-facial:before {
	  content: "\f11c";
	}

	.flaticon-data:before {
	  content: "\f11d";
	}

	.flaticon-facial-recognition:before {
	  content: "\f11e";
	}

	.flaticon-right-arrow:before {
	  content: "\f11f";
	}

	.flaticon-seo-and-web:before {
	  content: "\f120";
	}

	.flaticon-encrypt:before {
	  content: "\f121";
	}

	.flaticon-encrypt-1:before {
	  content: "\f122";
	}

	.flaticon-data-encryption:before {
	  content: "\f123";
	}

	.flaticon-tick:before {
	  content: "\f124";
	}

	.flaticon-success:before {
	  content: "\f125";
	}

	.flaticon-send:before {
	  content: "\f126";
	}

	.flaticon-left-arrow:before {
	  content: "\f127";
	}

	.flaticon-up-arrow:before {
	  content: "\f128";
	}

	.flaticon-download-arrow:before {
	  content: "\f129";
	}

	.flaticon-basket:before {
	  content: "\f12a";
	}

	.flaticon-stylish-bag:before {
	  content: "\f12b";
	}

	.flaticon-shopping-bag:before {
	  content: "\f12c";
	}

	.flaticon-bag:before {
	  content: "\f12d";
	}

	.flaticon-chat:before {
	  content: "\f12e";
	}

	.flaticon-robotics:before {
	  content: "\f12f";
	}

	.flaticon-qr:before {
	  content: "\f130";
	}

	.flaticon-production:before {
	  content: "\f131";
	}

	.flaticon-inclusion:before {
	  content: "\f132";
	}

	.flaticon-feature:before {
	  content: "\f133";
	}

	.flaticon-art-and-design:before {
	  content: "\f134";
	}

	.flaticon-search:before {
	  content: "\f135";
	}

	.flaticon-seo-and-web-1:before {
	  content: "\f136";
	}

	.flaticon-phone-1:before {
	  content: "\f137";
	}

	.flaticon-email:before {
	  content: "\f138";
	}

	.flaticon-error:before {
	  content: "\f139";
	}

	.flaticon-close:before {
	  content: "\f13a";
	}

	.flaticon-gps-1:before {
	  content: "\f13b";
	}

	.flaticon-cross-out:before {
	  content: "\f13c";
	}

	.flaticon-woman-bag:before {
	  content: "\f13d";
	}